import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-item-alert',
  templateUrl: './item-alert.component.html',
  styleUrls: ['./item-alert.component.scss']
})
export class ItemAlertComponent implements OnInit {

  @Input() error: HttpErrorResponse;

  constructor(
    private ngbActiveModal: NgbActiveModal,
  ) { }

  public ngOnInit(): void {
  }

  public closeModel(): void {
    this.ngbActiveModal.close();
  }
}
