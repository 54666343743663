import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home-page-banner',
  templateUrl: './home-page-banner.component.html',
  styleUrls: ['./home-page-banner.component.scss']
})
export class HomePageBannerComponent implements OnInit {
  routeSub: Subscription;
  constructor() { }

  ngOnInit() {
  }

}
