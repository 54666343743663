import { ErrorHandler, Injectable } from '@angular/core';
@Injectable()

export class Myerrorhandelor implements ErrorHandler {
  constructor() {
  }

  /**
   * @param error
   * @author Anku keshri
   * @description : Reload the page if there is any error of http client.
   */
  handleError(error) { }
}

