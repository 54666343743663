
import { InjectionToken } from '@angular/core';

export const API_SERVICE_CONFIG = new InjectionToken('api-service.config');

// const BASE_URL = 'https://staging.stntsol.com/admin/public/api/v1';
// const IMG_URL = 'https://staging.stntsol.com/admin/public/';

const BASE_URL = 'https://stntsol.com/admin/public/api/v1';
const IMG_URL = 'https://stntsol.com/admin/public/';



const  HEADERS = {
  'Content-Type' : 'application/json'
};


const AUTHORIZE_HEADERS = {
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
};

export const  DEFAULT_API_SERVICE_CONFIG = {
    headers: HEADERS,
    imageUrl: IMG_URL,
    authHeader: AUTHORIZE_HEADERS,
    userConfig: {
      apiPrefixName: `${BASE_URL}/`
    }
};


