import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private datePipe: DatePipe,
  ) { }

  public changeDateFormatToYYYMMDD(date) {
    return this.datePipe.transform(new Date(date), 'yyyy-MM-dd');
  }

  public getMinDate(date?: string) {
    const splitDate = date ? date.split('-') : null;
    const tempMinDate = new Date();
    tempMinDate.setDate(tempMinDate.getDate() - (100 * 365));
    if ((splitDate && splitDate.length) && (tempMinDate.getFullYear() > Number(splitDate[0]))) {
      const setOldDate = new Date(`${splitDate[0]}-${splitDate[1]}-${splitDate[2]}`);
      return {
        year: setOldDate.getFullYear(),
        month: setOldDate.getMonth() + 1,
        day: setOldDate.getDate(),
      };
    } else {
      return {
        year: tempMinDate.getFullYear(),
        month: tempMinDate.getMonth() + 1,
        day: tempMinDate.getDate(),
      };
    }
  }

  public getMaxDate() {
    return {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
    };
  }

  public setDateInEditField(value: any) {
    if (value) {
      const tempDate = new Date(value);
      return {
        year: tempDate.getUTCFullYear(),
        month: tempDate.getUTCMonth() + 1,
        day: tempDate.getUTCDate(),
      };
    }
  }

  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public alphanumeric(event): boolean {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9-#/ ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}

@Injectable()
export class CustomDateParserFormatter {
  format(date: NgbDateStruct): string {
    return date ? ('0' + date.month).slice(-2) + '/' + ('0' + date.day).slice(-2) + '/' + date.year : null
  }
}