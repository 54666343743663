import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from 'src/app/config/api-service.config';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PdfDownloadService {

   /**
   * apiPrefix: This variable is used to store the API prefix.
   */
  apiPrefix: string;
  headers: any;
  authHeader: any;
  AUTHORIZE_HEADERS = {
    'Content-Type': 'application/pdf',
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
  };

  private AUTHORIZE_HEADERS_TWO = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
  };

  constructor(private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) config) {
    this.apiPrefix = config.userConfig.apiPrefixName;
    this.authHeader = config.authHeader;
  }


  downloadPdf(pdfObj: any) {
    return this.http.get(`${this.apiPrefix}download/${pdfObj.productId}/${pdfObj.pdfName}`, {headers: this.AUTHORIZE_HEADERS});
  }

  toCallAjaxUrl(url: any) {
    return this.http.get(url, {responseType: 'blob'});
  }

  public maintainDownloadHistory(item: any) {
    const reqBody = { order_product_id: item.order_product_id };
    const headers = { headers: this.AUTHORIZE_HEADERS_TWO };
    return this.http.post(`${this.apiPrefix}download-status`, reqBody, headers);
  }

  tabIdToShow=new BehaviorSubject(1);
}
