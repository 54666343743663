import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntermediateStepService {

  userDetails = {
    full_company_name: null,
    suite_business: null,
    company_type: null,
    annual_revenue: null,
    billing_phone_number: null,
    line_of_business: null,
    years_in_business: null,
    company_address: null,
    billing_city: null,
    billing_state: null,
    billing_zip_code: null,
    ein: null,
    number_of_employees: null,
    duns: null,
    first_name: null,
    job_title: null,
    shipping_email: null,
    address: null,
    shipping_state: null,
    zip_code: null,
    security_question: null,
    last_name: null,
    phone_number: null,
    city: null,
    dob: null,
    security_answer: null
  };

  private facebookloggedInSource = new BehaviorSubject(this.userDetails);

  facebookloggedInSourceObservable = this.facebookloggedInSource.asObservable();

  constructor() { }

  changeUserDetail(data: any) {
    this.facebookloggedInSource.next(data);
  }
}
