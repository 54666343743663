import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from 'src/app/config/api-service.config';

@Injectable({
  providedIn: 'root'
})
export class PayBillInvoiceService {

  apiPrefix: string;
  headers: any;
  authHeader: any;


  constructor(private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) config) {
    this.apiPrefix = config.userConfig.apiPrefixName;
    this.authHeader = config.authHeader;
  }


  payInvoiceByNumber(data: any) {
    return this.http.post(`${this.apiPrefix}pay-my-bill`, data, {headers: this.authHeader});
  }
}
