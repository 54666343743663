import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  isActive:number;
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  navigateToUrl(param: number) {
    this.isActive=param;
    this.router.navigate(['/services'], { queryParams: { tabs: param } });
  }

}
