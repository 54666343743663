import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-maximum-office-amount',
  templateUrl: './maximum-office-amount.component.html',
  styleUrls: ['./maximum-office-amount.component.scss']
})
export class MaximumOfficeAmountComponent implements OnInit {
  @Input() error: HttpErrorResponse;

  constructor(private router: Router, private ngbActiveModal: NgbActiveModal) { }

  ngOnInit() {
  }

  public closeModel(): void {
    this.ngbActiveModal.close();
  }

  public placeOrder() {
    this.router.navigate(['/cart/checkout']);
    this.ngbActiveModal.close();
  }
}
