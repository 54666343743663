import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '../config/api-service.config';
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class UserService {
  /**
   * apiPrefix: This variable is used to store the API prefix.
   */
  apiPrefix: string;
  headers: any;
  authHeader: any;


  constructor(private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) config) {
    this.apiPrefix = config.userConfig.apiPrefixName;
    this.headers = config.headers;
    this.authHeader = config.authHeader;
  }

  /**
   * @method userRegistration
   * @param userData: It get the all info about user(first_name, last_name, password etc.)
   * @description It registered the user.
  */
  userRegistration = (userData: any) => {
    return this.http.post(`${this.apiPrefix}register`, userData, { headers: this.headers });
  }

  checkUserId(userId: any) {
    return this.http.post(`${this.apiPrefix}check-user-id`, userId, { headers: this.headers });
  }

  checkCouponCode(coupon: any) {
    return this.http.post(`${this.apiPrefix}check-coupon-code`, coupon, {headers: this.authHeader});
  }

  checkUserEmailId(emailId: any) {
    return this.http.post(`${this.apiPrefix}check-user-email`, emailId, { headers: this.headers });
  }

  getState() {
    return this.http.get(`${this.apiPrefix}state`, { headers: this.headers });
  }

  /**
   * @method login
   * @param email : Store the user name(string).
   * @param password : Store the password(string).
   * @description Only authorize user can login.
   */
  login = (email: string, password: string) => {
    return this.http.post<any>(`${this.apiPrefix}login`, { email: email, password: password }, {headers: this.headers})
      .pipe(map(data => {
        if (data && data.success) {
          localStorage.setItem('currentUser', JSON.stringify(data.success.user));
        }
        return data.success;
      }));
  }


  fogetPasswordStepFirst(userIdentity: any) {
    return this.http.post(`${this.apiPrefix}forget-step1`, {user_identity: userIdentity}, {headers: this.headers});
  }

  fogetPasswordStepTwo(secure_ques: string, secure_ans: string, email: string) {
    return this.http.post(`${this.apiPrefix}forget-step2`,
    {
      secure_ques: secure_ques,
      secure_ans: secure_ans,
      email: email
    }, {headers: this.headers});
  }


  /**
   * @method logout
   * @description It destroy the session or info wwhich is stored in the localstorage.
   */
  logout() {
    localStorage.removeItem('currentUser');
  }

  loginWithFacebook(userData: any) {
    return this.http.post(`${this.apiPrefix}social-login`, userData, {headers: this.headers});
  }

  userRegistrationWithSocial = (userData: any) => {
    return this.http.post(`${this.apiPrefix}social-update`, userData, { headers: this.headers });
  }

  userContactUs(userContactData: any) {
    return this.http.post(`${this.apiPrefix}contact`, userContactData, { headers: this.headers });
  }

  getCtegoryList(tierType: any) {
    return this.http.post(`${this.apiPrefix}category`, {tier_type: tierType}, {headers: this.headers});
  }

  getAllTypeProduct(tier_type: any) {
    return this.http.post(`${this.apiPrefix}products`, {tier_type: tier_type}, {headers: this.authHeader});
  }

  getSearchableProducts(product_title: any) {
    return this.http.post(`${this.apiPrefix}products`, {product_title: product_title}, {headers: this.authHeader});
  }

  getCartItem() {
    return this.http.get(`${this.apiPrefix}cart`, { headers: this.headers });
  }

  checkOrder() {
    return this.http.get(`${this.apiPrefix}check-order`, { headers: this.authHeader });
  }

  public sendOtpToEmail(userData: any) {
    return this.http.post(`${this.apiPrefix}send-otp`, userData, {
      headers: this.headers,
    })
  }

  public VerifyOtp(data: any) {
    return this.http.post(`${this.apiPrefix}verify-otp`, data, {
      headers: this.headers,
    })
  }
}
