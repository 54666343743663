import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Subscriber, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { API_SERVICE_CONFIG } from '../../config/api-service.config';

let products = JSON.parse(localStorage.getItem('cartItem')) || [];

@Injectable({
  providedIn: 'root'
})
export class ProductCartService {
  apiPrefix: string;
  authHeader: string;
  // Array
  public cartItems: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public observer: Subscriber<{}>;

  constructor(private toastrService: ToastrService,
    private router: Router,
    @Inject(API_SERVICE_CONFIG) config) {
    this.apiPrefix = config.userConfig.apiPrefixName;
    this.authHeader = config.authHeader;
    this.cartItems.subscribe(products => products = products);
  }

  // Get Products
  public getItems(): Observable<any[]> {
    const itemsStream = new Observable(observer => {
      observer.next(products);
      observer.complete();
    });
    return <Observable<any[]>>itemsStream;
  }

  // Add to cart
  public addToCart(product: any, quantity: number): any | boolean {
    var item: any | boolean = false;
    // If Products exist
    let hasItem = products.find((items, index) => {
      if (items.product.product_id === product.product_id) {
        return true;
      }
    });
    // If Products does not exist (Add New Products)
    if (hasItem && [hasItem.product].length) {
      this.toastrService.error('This product has been already added.');
    } else {
      item = { product: product, quantity: quantity };
      const foundProduct = products.filter((data: any) => {
        return data.product.virtual === product.virtual;
      });
      if (foundProduct && foundProduct.length) {
        products.push(item);
        this.toastrService.success('This product has been added successfully.');
      } else {
        this.toastrService.error('Virtual and Digital item cannot be added to the cart at the same time while purchasing Instant items.');
      }
    }
    localStorage.setItem('cartItem', JSON.stringify(products));
    this.router.navigate(['/cart']);
    return item;
  }

  // Update Cart Value
  public updateCartQuantity(product: any, quantity: number): any | boolean {
    return products.find((items, index) => {
      if (items.product.id === product.id) {
        let qty = products[index].quantity + quantity;
        let stock = this.calculateStockCounts(products[index], quantity);
        if (qty !== 0 && stock) {
          products[index]['quantity'] = qty;
          localStorage.setItem('cartItem', JSON.stringify(products));
        }
        return true;
      }
    });
  }

  // Calculate Product stock Counts
  public calculateStockCounts(product: any, quantity): any | Boolean {
    let qty = product.quantity + quantity;
    let stock = product.product.stock;
    if (stock < qty) {
      this.toastrService.error('You can not add more items than available. In stock ' + stock + ' items.');
      return false;
    }
    return true;
  }

  // Removed in cart
  public removeFromCart(item: any) {
    if (item === undefined) return false;
    const index = products.indexOf(item);
    products.splice(index, 1);
    localStorage.setItem("cartItem", JSON.stringify(products));
  }

  // Total amount 
  public getTotalAmount(): Observable<number> {
    return this.cartItems.pipe(map((product: any[]) => {
      return products.reduce((prev, curr: any) => {
        return prev + curr.product.price * curr.quantity;
      }, 0);
    }));
  }

}
