import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-account-suspended',
  templateUrl: './account-suspended.component.html',
  styleUrls: ['./account-suspended.component.scss']
})
export class AccountSuspendedComponent implements OnInit {

  @Input() error: HttpErrorResponse;

  constructor(
    private ngbActiveModal: NgbActiveModal,
  ) { }

  public ngOnInit(): void { }

  public closeModel(): void {
    this.ngbActiveModal.close();
  }
}
