import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '../config/api-service.config';

@Injectable({
  providedIn: 'root'
})
export class UpdateUserInfoService {


  AUTHORIZE_HEADERS = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
  };
 /**
   * apiPrefix: This variable is used to store the API prefix.
   */
  apiPrefix: string;
  headers: any;
  authHeader: any;


  constructor(private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) config) {
    this.apiPrefix = config.userConfig.apiPrefixName;
    this.authHeader = this.AUTHORIZE_HEADERS;
  }

  checkUserCurrentPassword(current_password: string) {
    return this.http.post(`${this.apiPrefix}check-password`,
      {current_password: current_password },
      {headers: this.authHeader});
  }

  updateNewPassword(new_password: string) {
    return this.http.post(`${this.apiPrefix}reset`,
    {new_password: new_password },
    {headers: this.authHeader});
  }

  updateUserProfile(userProfileData: any) {
    return this.http.post(`${this.apiPrefix}update-profile`, userProfileData, {headers: this.authHeader});
  }

  getUserProfile() {
    return this.http.get(`${this.apiPrefix}profile`,
    {headers: this.authHeader});
  }
}
