import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-why-we-choose',
  templateUrl: './why-we-choose.component.html',
  styleUrls: ['./why-we-choose.component.scss']
})
export class WhyWeChooseComponent implements OnInit {

  public showHelpOption: boolean;

  constructor() { }

  public ngOnInit() {
    this.showHelpOption = localStorage.getItem('currentUser') ? true : localStorage.getItem('token') ? true : false;
  }
}
