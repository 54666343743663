import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '../config/api-service.config';

@Injectable({
  providedIn: 'root'
})
export class AffiliateService {

 /**
   * apiPrefix: This variable is used to store the API prefix.
   */
  apiPrefix: string;
  headers: any;


  constructor(private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) config) {
    this.apiPrefix = config.userConfig.apiPrefixName;
    this.headers = config.headers;
  }

  affiliateInformation(affiliateData: any) {
    return this.http.post(`${this.apiPrefix}affiliate`, affiliateData, {headers: this.headers});
  }

  checkDuplicacyEmail(email: any) {
    return this.http.post(`${this.apiPrefix}check-affiliate-email`, email, {headers: this.headers});
  }

}
