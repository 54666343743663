import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { API_SERVICE_CONFIG } from '../config/api-service.config';
import { AddProduct } from '../models';
import { ItemAlertComponent } from '../shared/item-alert/item-alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MinimumOfficeAmountComponent } from '../shared/minimum-office-amount/minimum-office-amount.component';
import { MaximumOfficeAmountComponent } from '../shared/maximum-office-amount/maximum-office-amount.component';

@Injectable({
  providedIn: 'root'
})
export class CartDataService {

  /**
  * apiPrefix: This variable is used to store the API prefix.
  */
  apiPrefix: string;
  authHeader: any;


  constructor(private http: HttpClient,
    private ngbModal: NgbModal,
    @Inject(API_SERVICE_CONFIG) config) {
    this.apiPrefix = config.userConfig.apiPrefixName;
    this.authHeader = config.authHeader;
  }


  getCartItem() {
    return this.http.get(`${this.apiPrefix}cart`, { headers: this.authHeader });
  }

  addProductInCart(cartData: AddProduct) {
    return this.http.post(`${this.apiPrefix}add-item`, cartData, { headers: this.authHeader });
  }

  updateCartdataItem(cartData: AddProduct) {
    return this.http.post(`${this.apiPrefix}update-item`, cartData, { headers: this.authHeader });
  }

  deleteCartItem(cart_id: any) {
    return this.http.post(`${this.apiPrefix}delete-item`, cart_id, { headers: this.authHeader });
  }

  trackCode(trackedData: any) {
    return this.http.post(`${this.apiPrefix}track-affiliate`, trackedData, { headers: this.authHeader });
  }

  public addItemErrorPopup(error: HttpErrorResponse): void {
    const modalRef = this.ngbModal.open(ItemAlertComponent,
      { centered: true, backdrop: 'static', keyboard: false });
    const itemAlertComponent: ItemAlertComponent = modalRef.componentInstance;

    itemAlertComponent.error = error;

    modalRef.result.then((result: any) => { });
  }

  public minimumOfficeAmountErrorPopup(error): void {
    const modalRef = this.ngbModal.open(MinimumOfficeAmountComponent,
      {centered:true, backdrop: 'static', keyboard: false });
    const minimumOfficeAmountComponent: MinimumOfficeAmountComponent = modalRef.componentInstance;
    minimumOfficeAmountComponent.error = error;
    modalRef.result.then((result: any) => { });
  }

  public maximumOfficeAmountErrorPopup(error): void {
    const modalRef = this.ngbModal.open(MaximumOfficeAmountComponent, {centered:true, backdrop: 'static', keyboard: false });
    const maximumOfficeAmountComponent: MaximumOfficeAmountComponent = modalRef.componentInstance;
    maximumOfficeAmountComponent.error = error;
    modalRef.result.then((result: any) => { });
  }
}
