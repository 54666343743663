import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { LandingFixService } from '../../services/landing-fix.service';
import { CartItem } from '../../classes/cart-item';
import { CartService } from '../../services/cart.service';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UserService, SharedService } from '../../../services';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderOneComponent implements OnInit, OnDestroy {

  public shoppingCartItems: CartItem[] = [];

  allProductData = [];
  searcedhData = [];

  public model: any;
  product = [];
  show: boolean;
  searchData = '';
  loading = false;
  loggedInSubs: Subscription;

  formatter = (result: string) => result.toString().toUpperCase();

  constructor(private fix: LandingFixService,
    private cartService: CartService,
    private userService: UserService,
    private router: Router,
    private shared: SharedService) {
    this.cartService.getItems().subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
    this.loggedInSubs = this.shared.loggedInSouceObservable.subscribe((data: boolean) => {
      this.show = data;
    });
  }

  ngOnInit() {
    if (localStorage.getItem('token')) {
      this.getAppDownlableProductList();
      this.show = true;
    } else {
      this.show = false;
    }
    $.getScript('assets/js/menu.js');
  }

  ngOnDestroy() {
    this.loggedInSubs.unsubscribe();
  }

  searchProductGlobally(search: string) {
    if (search !== '') {
      this.loading = true;
      this.userService.getSearchableProducts(search).subscribe((data: any) => {
        this.searcedhData = data.success.productListing;
        this.loading = false;
        this.shared.setSearchItem(this.searcedhData);
        this.shared.changeSearchItem(this.searcedhData);
        setTimeout(() => {
          this.router.navigate(['/search']);
        }, 100);
      });
    }
  }

  onKeydown(event) {
    if (event.key === 'Enter') {
      if ($('.dropdown-menu.show').length === 0) {
        this.searchProductGlobally(event.target.value);
      }
    }
  }

  openNav() {
    this.fix.addNavFix();
  }

  closeNav() {
    this.fix.removeNavFix();
  }

  /**
   * @description: Search the product based on name
   */
  search = (text$: Observable<any>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term === '' ? []
        : this.product.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)))

  selectedItem(item) {
    const found = this.allProductData.filter((data: any) => {
      return data.product_name === item.item;
    });
    if (found && found.length) {
      this.router.navigate(['/category', found[0].slug]);
    }
  }

  getAppDownlableProductList() {
    const tierType = '';
    this.userService.getAllTypeProduct(tierType).subscribe((data: any) => {
      this.allProductData = data.success.productListing;
      for (const val of this.allProductData) {
        this.product.push(val.product_name);
      }
    });
  }
}
