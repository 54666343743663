import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SharedService, UserService } from '../../../../services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AccountSuspendedComponent } from 'src/app/shared/account-suspended/account-suspended.component';
import { InvoiceService } from 'src/app/my-acccount/services';
declare var $: any;
@Component({
  selector: 'app-loginbar',
  templateUrl: './loginbar.component.html',
  styleUrls: ['./loginbar.component.scss']
})
export class LoginbarComponent implements OnInit, OnDestroy {

  show: boolean;
  showPassword = false;


  loginForm: FormGroup;
  submitted = false;
  loading = false;
  url: string;
  loggedIn: boolean;
  cartLength = 0;

  forgetPasswordForm: FormGroup;
  submittedForgot = false;
  closeResult: string;
  userDetails: any;
  tierOne = '/category/downloadable';
  tierTwo = '/category/nondownloadable';
  checkTheType: number | null;
  remainingCredit: number | null;
  data: any;
  notCreateOrder = false;


  forgetPasswordFormTwo: FormGroup;
  submittedForgotPassRequest = false;
  selectedQuestion: string;
  currentUrl: string;

  modalReference: any;
  sharedLinkSubs: Subscription;

  public isCollapsed = false;
  hideLoginPopup = true;

  cartItemSubs: Subscription;
  status: boolean = false;
  constructor(private shared: SharedService,
    private formbuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private invoiceService: InvoiceService,
    public toastr: ToastrManager,
    private modalService: NgbModal) {
    this.shared.loggedInSouceObservable.subscribe((data: any) => {
      this.show = data;
    });
    this.sharedLinkSubs = this.shared.linkActiveSourceSourceObservable.subscribe((data: any) => {
      this.checkTheType = data;
    });

    this.cartItemSubs = this.shared.cartItemSourceObservable.subscribe((cartItem: number) => {
      this.cartLength = cartItem;
    });
    this.shared.currentTradeAmount.subscribe((data: any) => {
      this.remainingCredit = data;
      if (this.remainingCredit === null) {
        this.getAllInvoiceInformation();
      }
    });

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.currentUrl = val.url;
      }
    });
  }

  ngOnInit() {
    this.shared.signInSourceSourceObservable.subscribe((data: any) => {
      if (data === true) {
        setTimeout(() => {
          $('.sign-in-popup').show();
          $('.formHead').click();
        }, 700);
      }
    });
    if (localStorage.getItem('token')) {
      this.show = true;
    } else {
      this.show = false;
    }


    this.loginForm = this.formbuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });


    this.forgetPasswordForm = this.formbuilder.group({
      user_identity: ['', [Validators.required]]
    });

    this.forgetPasswordFormTwo = this.formbuilder.group({
      secure_ans: ['', [Validators.required]],
      email: ['', [
        Validators.required,
        Validators.email]
      ]
    });
  }


  /**
   * Get all info of invoice
   */
   getAllInvoiceInformation() {
    this.loading = true;
    this.invoiceService.getInvoiceInfo().subscribe((data: any) => {
      this.loading = false;
      // console.table('details data', data);
      if (data) {
        this.data = data.success.orderDetails;
        this.data.filter((item: any) => {
          if (item.current_payment_status_name === 'Unpaid') {
            this.notCreateOrder = true;
          }
          const newDate = item.created_at.split('T');
          return item.created_at = newDate[0];
        });
        // this.sortData(this.data);
        this.userDetails = data.success.userDetails;
        this.remainingCredit = data.success.userDetails.remaining_credit;
        setTimeout(() => {
          $( "li > a:contains('«')" ).html('Prev');
          $( "li > a:contains('»')" ).html('Next');
        }, 100);
      }
    }, (error: HttpErrorResponse) => {
      this.loading = false;
    });
  }

  ngOnDestroy() {
    this.sharedLinkSubs.unsubscribe();
    this.cartItemSubs.unsubscribe();
  }

  showSignInPopup() {
    $('.sign-in-popup').show();
  }
  hideSignInPopup() {
    $('.sign-in-popup').hide();
    this.submitted = false;
  }


  get f() { return this.loginForm.controls; }

  loginFormData(event) {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.userService.login(this.loginForm.value.email, this.loginForm.value.password)
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.loading = false;
          localStorage.setItem('token', JSON.stringify(data.user.token));
          window.location.href = 'category';
          this.shared.changeLoginCondition(true);
          this.toastr.successToastr(data.message, 'Success');
          this.loginForm.reset();
        }, (error: HttpErrorResponse) => {
          this.loading = false;
          if (error.error.error.status === 2) {
            this.hideSignInPopup();
            this.showAccountSuspendedError(error);
          } else {
            this.toastr.errorToastr(error.error.error, '!Oops', { showCloseButton: true });
          }
        });
  }

  private showAccountSuspendedError(error: HttpErrorResponse): void {
    const modalRef = this.modalService.open(AccountSuspendedComponent,
      { centered: true, backdrop: 'static', keyboard: false });
    const accountSuspendedComponent: AccountSuspendedComponent = modalRef.componentInstance;

    accountSuspendedComponent.error = error;

    modalRef.result.then((result: any) => { });
  }

  OpenForgotPassword(content) {
    this.hideSignInPopup();
    this.hideLoginPopup = false;
    this.modalReference = this.modalService.open(content, { backdrop: 'static', keyboard: false });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.hideLoginPopup = true;
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  get forgotPas() { return this.forgetPasswordForm.controls; }

  forgetPassword(contentrequest) {
    this.submittedForgot = true;
    if (this.forgetPasswordForm.invalid) {
      return;
    }
    this.loading = true;

    this.userService.fogetPasswordStepFirst(this.forgetPasswordForm.value).subscribe((data: any) => {
      this.loading = false;
      this.forgetPasswordForm.reset();
      this.selectedQuestion = data.success.secure_ques;
      this.modalService.dismissAll();
      this.submittedForgot = false;
      this.modalReference = this.modalService.open(contentrequest);
      this.modalReference.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    },
      (error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          if (!navigator.onLine) {
            this.toastr.errorToastr('Connection Lost! You are not connected to internet.', 'Oops!', {
              position: 'top-right',
              showCloseButton: true
            });
            return;
          }
        }
        this.loading = false;
        this.toastr.errorToastr(error.error.error, '!Oops', {
          position: 'top-right',
          showCloseButton: true
        });
      });
  }


  get forgotPasswordRequest() { return this.forgetPasswordFormTwo.controls; }

  forgetPasswordStepTwo() {
    this.submittedForgotPassRequest = true;
    if (this.forgetPasswordFormTwo.invalid) {
      return;
    }
    this.loading = true;
    this.userService.fogetPasswordStepTwo(this.selectedQuestion,
      this.forgetPasswordFormTwo.value.secure_ans, this.forgetPasswordFormTwo.value.email).subscribe((data: any) => {
        this.loading = false;

        this.forgetPasswordFormTwo.reset();
        this.submittedForgotPassRequest = false;
        localStorage.setItem('currentUserDataStepTwo', JSON.stringify(data.success));
        this.modalReference.close();
        this.router.navigate(['/forgot-password-success']);
      }, (error: HttpErrorResponse) => {
        this.loading = false;
        this.toastr.errorToastr(error.error.error, '!Oops', { showCloseButton: true });
      });
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.clear();
    this.toastr.successToastr("Successful", "Logout", { showCloseButton: true });
    this.shared.changeLoginCondition(false);
    this.router.navigate(['/']);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }


  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  navigateTo(url) {
    this.router.navigate([`${url}`]);
  }

}
