import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from 'src/app/config/api-service.config';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  AUTHORIZE_HEADERS = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
  };

  /**
   * apiPrefix: This variable is used to store the API prefix.
   */
  apiPrefix: string;
  headers: any;
  authHeader: any;


  constructor(private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) config) {
    this.apiPrefix = config.userConfig.apiPrefixName;
    this.authHeader = this.AUTHORIZE_HEADERS;
  }

  cancelSubscription(subscriptionId: any) {
    return this.http.post(`${this.apiPrefix}cancel-subscription`, subscriptionId,
    {headers: this.authHeader});
  }

  updateSubscription(subscription: any) {
    return this.http.post(`${this.apiPrefix}update-subscription`, subscription,
    {headers: this.authHeader});
  }


  getSpecificUserSubscriptionData(userSubscriptionId: any) {
    return this.http.post(`${this.apiPrefix}my-subscriptions`, userSubscriptionId,
    {headers: this.authHeader});
  }
}
