import { Component, OnInit, OnDestroy, HostListener, ElementRef } from '@angular/core';
import { SharedService } from './services';
import { Router, NavigationStart, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { Subscription, Observable, fromEvent } from 'rxjs';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  connectionStatusMessage: string;
  connectionStatus: string;
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  queryParamRoute: Subscription;

  readonly googlePlayLink: string;
  readonly appStoreLink: string;
  routeSub: Subscription;
  ipAddress: any;
  routeUrl: string;

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    if (localStorage.getItem('currentUser') === null) {
      const splitUrl = this.routeUrl.split('/');
      if ((splitUrl.indexOf('category')) > -1) {
        this.shared.changeLoginCondition(false);
        this.router.navigate(['/']);
        return;
      } else if ((splitUrl.indexOf('my-account')) > -1) {
        this.shared.changeLoginCondition(false);
        this.router.navigate(['/']);
      } else if ((splitUrl.indexOf('cart')) > -1) {
        this.shared.changeLoginCondition(false);
        this.router.navigate(['/']);
      } else {
        this.shared.changeLoginCondition(false);
      }
    }
  }


  constructor(
    private shared: SharedService,
    private router: Router,
    private toast: ToastrManager,
    private route: ActivatedRoute,
    private userIdle: UserIdleService,
    public location: Location,
    public modalService: NgbModal,
    private _element: ElementRef) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.routeUrl = event.url;
        if (event.url !== '/cart/checkout') {
          localStorage.removeItem('payByInvoice');
          this.shared.showDataOnCheckOut([]);
        }
        if (event.url !== '/category') {
          $('.modal-backdrop').remove();
        }
        if (event.url !== '/cart/checkout') {
          localStorage.removeItem('payByInvoice');
          this.shared.showDataOnCheckOut([]);
        }

        // for (const val of [1]) {
        $('.sign-in-popup').hide();
        $('.global-s').val('');
        this.shared.changeActiveLinkNav(null);
        // }
      }
      if (event instanceof NavigationEnd) {
        window.scroll(0, 0);
        this.queryParamRoute = this.route.queryParamMap.subscribe((params: any) => {
          const queryParam = this.route.snapshot.queryParamMap.get('track');
          if (queryParam !== null) {
            this.router.navigate(['/register'], { queryParams: { track: queryParam } })
          }
        });
      }

    });
  }

  ngOnInit() {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      this.toast.successToastr('Internet connected', 'Success', { showCloseButton: true });
      if (this.routeUrl === '/') {
        this.router.navigate(['/']);
      }
      this.location.back();
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';
      this.toast.errorToastr(this.connectionStatusMessage, '!Oops', { showCloseButton: true });
      this.router.navigate(['/connection-failure']);
    }));

    // Start watching for user inactivity.
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => {
      const eventList = [
        'click',
        'mouseover',
        'keydown',
        'DOMMouseScroll',
        'mousewheel',
        'mousedown',
        'touchstart',
        'touchmove',
        'scroll',
        'keyup'
      ];
      for (const event of eventList) {
        document.body.addEventListener(event, () => this.userIdle.resetTimer());
      }
    });

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('token');
      localStorage.clear();
      this.shared.changeLoginCondition(false);
      this.router.navigate(['/']);
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    /**
    * Unsubscribe all subscriptions to avoid memory leak
    */
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }


  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }

  @HostListener('click', ['$event.target.id'])
  onClick(btn) {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      if (btn === 'side-bar') {
      } else {
        let smallBox = this._element.nativeElement.querySelector('.mobile-back');
        smallBox.dispatchEvent(new Event('click'));
      }
    } else if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua)) {
      if (btn === 'side-bar') {
      } else {
        let smallBox = this._element.nativeElement.querySelector('.mobile-back');
        smallBox.dispatchEvent(new Event('click'));
      }
    }
  }
}
