import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sage',
  templateUrl: './sage.component.html',
  styleUrls: ['./sage.component.scss']
})
export class SageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
