import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_SERVICE_CONFIG } from 'src/app/config/api-service.config';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  AUTHORIZE_HEADERS = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
  };

  /**
   * apiPrefix: This variable is used to store the API prefix.
   */
  apiPrefix: string;
  headers: any;
  authHeader: any;


  constructor(private http: HttpClient,
    @Inject(API_SERVICE_CONFIG) config) {
    this.apiPrefix = config.userConfig.apiPrefixName;
    this.authHeader = this.AUTHORIZE_HEADERS;
  }

  getInvoiceInfo() {
    return this.http.get(`${this.apiPrefix}invoice`,
    {headers: this.authHeader});
  }

  getMyDownloadsProduct() {
    return this.http.get(`${this.apiPrefix}my-downloads`,
    {headers: this.authHeader});
  }

  getMyDownloadProducts(requestHeader: any) {
    return this.http.post(`${this.apiPrefix}my-downloads`, requestHeader,
    {headers: this.authHeader});
  }

  reOrderItems(order_id: any) {
    return this.http.post(`${this.apiPrefix}re-order`, {order_id: order_id},
    {headers: this.authHeader});
  }
}
