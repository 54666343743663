import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscriber, Observable } from 'rxjs';


const searchResult = JSON.parse(localStorage.getItem('searchTerms')) || [];
const dfaultCartLength = JSON.parse(localStorage.getItem('cartLength')) || 0;
const payByInvoiceResult = JSON.parse(localStorage.getItem('payByInvoice')) || [
];

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private signInSource = new BehaviorSubject(false);
  signInSourceSourceObservable = this.signInSource.asObservable();

  private logoutSource = new BehaviorSubject(false);
  logoutSourceObservable = this.logoutSource.asObservable();

  private cartItemSource = new BehaviorSubject(dfaultCartLength);
  cartItemSourceObservable = this.cartItemSource.asObservable();

  private searchItemSource = new BehaviorSubject([]);
  searchtemSourceObservable = this.searchItemSource.asObservable();

  public cartItems:  BehaviorSubject<any[]> = new BehaviorSubject([]);
  public observer:  Subscriber<{}>;

  private isloggedInSource = new BehaviorSubject(false);
  loggedInSouceObservable = this.isloggedInSource.asObservable();

  private payinvoiceItemSource = new BehaviorSubject(payByInvoiceResult);
  payinvoiceSourceObservable = this.payinvoiceItemSource.asObservable();

  private linkActiveSource = new BehaviorSubject(null);
  linkActiveSourceSourceObservable = this.linkActiveSource.asObservable();

  private updateGroupSub = new BehaviorSubject(null);
  public updateGroupObs = this.updateGroupSub.asObservable();

  private tradelineAmount = new BehaviorSubject(null);
  public currentTradeAmount = this.tradelineAmount.asObservable();

  fPassword=new BehaviorSubject(false);
   
  constructor() { }

  changeLoginCondition(data: boolean) {
    this.isloggedInSource.next(data);
  }

  changeLogoutCondition(data: boolean) {
    this.logoutSource.next(data);
  }

  changeCartItemLength(length: any) {
    localStorage.setItem('cartLength', length);
    this.cartItemSource.next(length);
  }

  changeActiveLinkNav(virtual) {
    this.linkActiveSource.next(virtual);
  }

  setSearchItem(data: any[]) {
    if (data) {
      searchResult.push(data);
      localStorage.setItem('searchTerms', JSON.stringify(searchResult));
    }
    return;
  }

  changeSearchItem(data) {
    this.searchItemSource.next(data);
  }

  public getSearchItems(): Observable<any[]> {
    const searchStream = new Observable(observer => {
      observer.next(searchResult);
      observer.complete();
    });
    return <Observable<any[]>>searchStream;
  }


  showDataOnCheckOut(data) {
    this.payinvoiceItemSource.next(data);
  }


  openSignInPopup(data) {
    this.signInSource.next(data);
  }

  openForgotPassword(){
    return this.fPassword.asObservable();
  }
  setForgotPassword(val:any){
    this.fPassword.next(val);
  }

  public alphanumeric(event): boolean {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9-#/ ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  
  public updateCheckOrder(status){
    this.updateGroupSub.next(status); 
  }

  public updateTradeAmount(data) {
    this.tradelineAmount.next(data); 
  }
}
