import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { rootRouterConfig } from './app.routes';
import { HttpClientModule} from '@angular/common/http';

import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';


import { API_SERVICE_CONFIG, DEFAULT_API_SERVICE_CONFIG } from './config/api-service.config';
import { AuthGuard } from './guard/auth-guard';
import { HomePageBannerComponent } from './main/home-page-banner/home-page-banner.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { IsotopeModule } from 'ngx-isotope';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserIdleModule } from 'angular-user-idle';
import { CustomDateParserFormatter, Myerrorhandelor } from './services';
import { GlobalErrorHandlerService } from './global-error-handler';
import { LazyLoadScriptService } from './lazy-load-script.service';
import { DatePipe } from '@angular/common';
// import { FilterImagePipe } from './pipes/filter-image.pipe';
import { Ng2DeviceDetectorModule } from 'ng2-device-detector';
import { ConstantVariables } from 'src/const/constant';
@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HomePageBannerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    SlickCarouselModule,
    IsotopeModule,
    NgbModule,
    HttpClientModule,
    UserIdleModule.forRoot({idle: 60 * 30, timeout: 60, ping: 120}),
    Ng2DeviceDetectorModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      progressBar: false,
      enableHtml: true,
      preventDuplicates: true
    }),
    RouterModule.forRoot(rootRouterConfig, {
      preloadingStrategy: PreloadAllModules, useHash: false, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' })
  ],
  providers: [
    DatePipe,
    {
    provide: API_SERVICE_CONFIG,
    useValue: DEFAULT_API_SERVICE_CONFIG
    },
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateParserFormatter
    },
    AuthGuard,
    {
      provide: ErrorHandler, useClass: Myerrorhandelor
    },
    GlobalErrorHandlerService,
    ConstantVariables,
   LazyLoadScriptService
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
