import { Routes } from '@angular/router';

import { MainComponent } from './main/main.component';
import { AuthGuard } from './guard/auth-guard';
import { ErrorPageComponent } from './shared/error-page/error-page.component';
import { NoConnectionComponent } from './shared/no-connection/no-connection.component';

export const rootRouterConfig: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pre-log/pre-log.module').then(m => m.PreLogModule)
      },
      {
        path: 'my-account',
        loadChildren: () => import('./my-acccount/my-acccount.module').then(m => m.MyAcccountModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'category',
        loadChildren: () => import('./category/category.module').then(m => m.CategoryModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'cart',
        loadChildren: () => import('./cart/cart.module').then(m => m.CartModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'connection-failure',
        component: NoConnectionComponent
      },
      {
        path: '**',
        component: ErrorPageComponent
      },
    ]
  }
];

